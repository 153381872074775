import { ImageValidator, VideoValidator, I18nValidator, LinksValidator, TitleValidator } from '~/validators'
import hotTitleTypeWritter from '~/components/Organisms/TitleTypeWritter/TitleTypeWritter.vue'
import hotVideoInline from '~/components/Molecules/VideoInline/VideoInline.vue'
import stripe from '~/mixins/stripe.js'

export default {
  mixins: [stripe],

  props: {
    logo: {
      type: Object,
      validator: prop => ImageValidator(prop, 'logo'),
    },

    title: {
      type: [String, Object],
      required: true,
      validator: prop => TitleValidator(prop, 'title'),
    },

    titleAnnotations: {
      type: String,
    },

    background: {
      type: String,
    },

    campaign: {
      type: Boolean,
      default: false,
    },

    body: {
      type: String,
    },

    bodyAnnotations: {
      type: String,
    },

    buttons: {
      type: Array,
      validator: prop => LinksValidator(prop, 'buttons'),
    },

    typewritter: {
      type: Object,
    },

    buttonAnnotations: {
      type: String,
    },

    links: {
      type: Object,
      validator: prop => {
        I18nValidator(prop, 'links')

        return LinksValidator(prop.args, 'links')
      },
    },

    placeholder: {
      type: Object,
      validator: prop => {
        if (prop.image) return ImageValidator(prop, 'placeholder')
        if (prop.video) return VideoValidator(prop, 'placeholder')

        return false
      },
    },

    center: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    hotTitleTypeWritter,
    hotVideoInline,
  },

  computed: {
    placeholderClasses() {
      const classes = []

      if (this.hasImagePlaceholder) classes.push('hero--placeholder-image')
      if (this.hasImagePlaceholder && this.hasOverlappedImagePlaceholer) classes.push('hero--placeholder-image-overlap')
      if (this.hasVideoPlaceholder) classes.push('hero--placeholder-video')

      return classes
    },

    hasImagePlaceholder() {
      return !!this.placeholder?.image
    },

    hasVideoPlaceholder() {
      return !!this.placeholder?.video
    },

    hasOverlappedImagePlaceholer() {
      return !!this.placeholder?.image?.overlap
    },

    isTitleTypeString() {
      return typeof this.title === 'string' ? true : false
    },

    centerClasses() {
      if (this.center) return 'hero--center'
    },
  },
}
