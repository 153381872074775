export default {
  props: {
    tag: {
      type: String,
      default: 'span',
    },

    label: {
      type: String,
      default: '',
    },

    typewritter: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      timeArray: [50, 90],
      counter: 0,
      word: '',
      wordLength: 0,
      wordBox: '',
      letterArray: [],
      spellTimer: null,
      deleteTimer: null,
      animate: true,
    }
  },

  computed: {
    splitTitle() {
      const label = typeof this.label === 'string' ? this.label : this.label.label

      return label.split('|')
    },
  },

  methods: {
    init() {
      if (this.animate) {
        if (this.counter > this.typewritter.prashes.length - 1) this.counter = 0

        this.word = this.typewritter.prashes[this.counter]
        this.wordLength = this.word.length
        this.letterArray = []
        this.wordBox = this.$refs['hero__word-box']

        for (let i = 0; i < this.wordLength; i++) {
          this.letterArray.push(this.word.charAt(i))
        }

        this.spell(this.wordLength)
      }
    },

    spell(wordLength) {
      let spellSpeed = this.timeArray[Math.floor(Math.random() * this.timeArray.length)]

      this.spellTimer = setTimeout(() => {
        this.wordBox.innerHTML += this.letterArray[this.wordLength - wordLength]

        if (--wordLength) this.spell(wordLength)
        else
          setTimeout(() => {
            this.delete()
          }, 2000)
      }, spellSpeed)
    },

    delete() {
      this.deleteTimer = setTimeout(() => {
        const string = this.wordBox.textContent
        const newString = string.substring(0, string.length - 1)
        this.wordBox.textContent = newString

        if (--this.wordLength) this.delete()
        else
          setTimeout(() => {
            this.counter += 1
            this.init()
          }, 250)
      }, 50)
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.init()
    })
  },

  render(createElement) {
    const tag = this.tag
    let attributes = {
      attrs: {
        class: ['title'],
      },
    }
    const slot = this.$slots.default

    if (this.label)
      attributes = {
        ...attributes,
        domProps: {
          innerHTML: this.label,
        },
      }

    return createElement(tag, attributes, slot)
  },

  beforeDestroy() {
    this.animate = false
  },
}
